export const MobileBottomNavigationTranslations = {
  en: {
    mobileBottomNavigation: {
      secondaryMenuTitle: `Categories`,
    }
  },
  zh: {
    mobileBottomNavigation: {
      secondaryMenuTitle: `類別`,
    }
  },
  fr: {
    mobileBottomNavigation: {
      secondaryMenuTitle: `Catégories`,
    }
  },
  ro: {
    mobileBottomNavigation: {
      secondaryMenuTitle: `Categorii`,
    }
  },
  es: {
    mobileBottomNavigation: {
      secondaryMenuTitle: `Categorías`,
    }
  },
  pt: {
    mobileBottomNavigation: {
      secondaryMenuTitle: `Categorias`,
    }
  },
};
